import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResModel } from 'countable@model';
import { ToastrService } from 'ngx-toastr';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  private static readonly API = environment.apiV2 + '/user';
  private readonly GET_ALL_USER_TITLE = environment.apiV1 + '/user' + '/all-user-title';
  private readonly GET_ALL_USER_ACCESS_TYPE = environment.apiV1 + '/user' + '/access-type-all';

  constructor(private http: HttpClient, private toaster: ToastrService) {
  }

  public resendInvitationLink(email): Observable<any> {
    return this.http.post(UserApiService.API + '/invitation-mail', email, {responseType: 'json'});
  }

  public getUserRole(): Observable<any> {
    return this.http.get(environment.apiV1 + '/user/user-role');
  }

  public getAccessTypeData(userAccountId): Observable<GenericResModel<string>> {
    return this.http.get<GenericResModel<string>>(environment.apiV1 + '/user/access-type-data', {params: {userAccountId: userAccountId}});
  }

  public getAllUserTitles(roleName: string): Observable<string> {
    return this.http.get<GenericResModel<string>>(this.GET_ALL_USER_TITLE, {params: {roleName: roleName}})
      .pipe(map(res => {
        if (res.status !== 200 && res.status !== 204) {
          res.errors.forEach(err => this.toaster.warning(err));
        }
        return res.data;
      }));
  }

  public getAllUserAccessType(): Observable<string> {
    return this.http.get<GenericResModel<string>>(this.GET_ALL_USER_ACCESS_TYPE)
      .pipe(map(res => {
        if (res.status !== 200 && res.status !== 204) {
          res.errors.forEach(err => this.toaster.warning(err));
        }
        return res.data;
      }));
  }
}
